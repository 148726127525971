import { useState, useEffect, createContext, useContext } from "react";
import { usePocketBase } from "./PocketBaseProvider";

const AuthContext = createContext({
  signOut: () => {
    // Default to a no-op function.
  },
});

// This hook can be used to access the user info.
export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const { pb } = usePocketBase();
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const checkAuthStatus = async () => {
      if (pb) {
        // Check if the user is logged in using PocketBase
        const loggedIn = await pb.authStore.isValid;
        setIsLoggedIn(loggedIn);
        setUser(loggedIn ? pb.authStore.model : null);
        setIsInitialized(true);
        //check if the user is verified
        if (loggedIn) {
          if (
            pb.authStore.model.verified === false &&
            window.location.pathname !== "/#app/verification"
          ) {
            console.log("User is not verified");
            //redirect to verification page

            if (window.location.pathname !== "/#app/verification") {
              window.location.href = "/#app/verification";
            }
          }
        }
      }
    };

    checkAuthStatus();
  }, [pb]);

  const login = async (email, password) => {
    setIsLoading(true);
    try {
      // Attempt to login using PocketBase
      await pb.collection("admins").authWithPassword(email, password);
      // If login successful, update state
      console.log("Logged in successfully", email, password);
      setIsLoggedIn(true);
      setUser(pb.authStore.model);
      return { success: true, user: pb.authStore.model };
    } catch (error) {
      console.error("Login error:", error);
      return { success: false, error: error.message };
    } finally {
      setIsLoading(false);
    }
  };

  const register = async (email, password, name, username, cui, companie) => {
    setIsLoading(true);
    const data = {
      email: email,
      emailVisibility: false,
      cui: cui,
      companie: companie,
      password: password,
      passwordConfirm: password,
      name: name,
      username: username,
    };
    try {
      // Register a new user using PocketBase
      await pb.collection("admins").create(data); // If registration successful, update state
      await pb.collection("admins").requestVerification(email);

      setIsLoggedIn(true);
      setUser(pb.authStore.model);
      return { success: true, user: pb.authStore.model };
    } catch (error) {
      console.error("Registration error:", error);
      return { success: false, error: error.message };
    } finally {
      setIsLoading(false);
    }
  };

  const signOut = async () => {
    setIsLoading(true);
    try {
      // Sign out the user using PocketBase
      await pb.authStore.signOut();
    } catch (error) {
      console.error("Sign out error:", error);
    } finally {
      setIsLoading(false);
      // Regardless of success or failure, redirect to the login page
      window.location.href = "/login";
    }
  };

  if (!isInitialized) {
    return null;
  }

  const forgetPassword = async (email) => {
    setIsLoading(true);
    try {
      // Attempt to login using PocketBase
      await pb.collection("admins").requestPasswordReset(email);
      // If login successful, update state
      console.log("Password reset email sent successfully", email);
      return { success: true };
    } catch (error) {
      console.error("Password reset error:", error);
      return { success: false, error: error.message };
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        register,
        signOut,
        isLoading,
        isLoggedIn,
        isInitialized,
        user,
        pb,
        forgetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
