import React, { lazy, useEffect, useState } from "react";
import "./App.css";
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { themeChange } from "theme-change";
import { useAuth } from "./app/auth";

// Importing pages
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Register = lazy(() => import("./pages/Register"));
const Documentation = lazy(() => import("./pages/Documentation"));
const Verified = lazy(() => import("./pages/protected/Verified"));

function App() {
  const { user } = useAuth();

  console.log(user);
  useEffect(() => {
    themeChange({
      theme: localStorage.getItem("theme") || "light",
    });
    if (localStorage.getItem("theme") !== "light") {
      localStorage.setItem("theme", "light");
    }
    localStorage.setItem("theme", "light");
    console.log(user);
    if (
      user?.verified === false &&
      window.location.pathname !== "/#app/verification"
    ) {
      console.log("User is not verified");
      //redirect to verification page
      window.location.href = "/#app/verification";
    }
  }, [user]);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          {/* Place new routes over this */}
          <Route path="/app/*" element={<Layout />} />

          <Route
            path="*"
            element={
              <Navigate to={user ? "/app/dashboard" : "/login"} replace />
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
