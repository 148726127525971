// PocketBaseProvider.js
import React, { createContext, useContext, useState, useEffect } from "react";
import PocketBase, { LocalAuthStore } from "pocketbase";

const PocketBaseContext = createContext();

export const usePocketBase = () => useContext(PocketBaseContext);

export const PocketBaseProvider = ({ children }) => {
  const [pb, setPb] = useState();

  useEffect(() => {
    const initializePocketBase = async () => {
      const pb = new PocketBase("https://db.webrio.ro/");
      setPb(pb);
    };

    initializePocketBase();
  }, []);

  return (
    <PocketBaseContext.Provider value={{ pb }}>
      {children}
    </PocketBaseContext.Provider>
  );
};
